$blue: #1475af;
$light-blue: #1a87c2;
$info: $light-blue;
$dark: #0B0B0B;
$primary: $blue;
$menu-item-active-background-color: $blue;
$pagination-current-background-color: $blue;
$pagination-current-border-color: darken($blue, 5%);
$menu-list-link-padding: 0.75rem;

$size-1: 2.3rem;
$size-2: 2rem;
$size-3: 1.7rem;


$slick-loader-path: '../node_modules/slick-carousel/slick/';
$slick-font-path: '../node_modules/slick-carousel/slick/fonts/';

@import "~bulma";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
body {
  overflow: hidden;
}
.container {
  max-width: 1152px;
  &:not(.footer) {
    margin-bottom: 2rem;
  }
}
.page-meta {
  margin-top: 1rem;
  margin-bottom: -2rem;
  .print {
    color: $dark;
    font-size: .9rem;
    &:before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 14px;
      margin-right: .4rem;
      background: url('img/print.png') no-repeat;
    }
  }
}
.slick-slider {
  .slick-slide {
    float: none;
    display: inline-block;
    vertical-align: top;
  }
  @include until($desktop) {
    .slick-prev, .slick-next {
      display: none !important;
    }
  }
}
.img-gallery, .gallery, .img-slider {
  .slick-arrow {
    z-index: 1000;
    opacity: 0.2;
    &:before {
      color: $dark;
    }
  }
  .slick-prev {
    left: 5px;
  }
  .slick-next {
    right: 5px;
  }
  &:hover {
    .slick-arrow {
     opacity: 1;
    }
  }
}
.gallery {
  margin-left: -0.75rem;
  margin-right: -0.5rem; // special thing.
  .slick-slide {
    padding: 0.75rem;
  }
  .slick-prev {
    left: -15px;
  }
  .slick-next {
    right: -15px;
  }
  .item {
    display: inline-block;
    img {
      width: 100%;
    }
  }
}
.is-flex-vcentred {
  align-items: center;
}
.header {
  .logo {
    width: 370px;
    background: url('img/logo.png') no-repeat 50% 50%;
    height: 85px;
    box-sizing: content-box;
    margin-bottom: -.5rem;
    @include until($desktop) {
      background-size: contain;
      margin: 0 auto;
      .slogan {
        display: none;
      }
    }
  }
  .contact-popup {
    height: 2em;
    @include from($desktop) {
      margin-bottom: -.4rem;
    }
  }
  .navbar-item.contact-item {
    flex: 1;
    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .navbar-end.contact-item {
    flex-direction: column;
  }
  .contact-item {
    display: flex;
    text-align: center;
    color: $dark;
    .phone {
      margin-top: .3rem;
      color: $dark;
      font-weight: bolder;
      &:before {
        content: "";
        width: 18px;
        height: 17px;
        margin-right: 5px;
        background: url('img/phone.png') no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        transform: rotate(20deg);
      }
    }
    .email {
      display: inline-block;
      margin-bottom: .3rem;
      color: $dark;
      font-weight: bold;
    }
  }
  .navbar {
    flex-flow: wrap;
    background: transparent;
    .navbar-brand {
      @include until ($desktop) {
        flex-direction: column;
        .navbar-burger {
          display: flex;
          justify-content: space-between;
          width: 100%;
          background: $blue;
          padding: 0 .5rem;
          align-items: center;
          .burger {
            cursor: pointer;
            display: block;
            height: 100%;
            width: 60px;
            span {
              left: 8px;
              background: $white;
            }
          }
          .search {
            .has-input {
              opacity: 0;
              pointer-events: none;
              transition: opacity 100ms ease-in-out;
            }
            &.is-active {
              .has-input {
                opacity: 1;
                pointer-events: auto;
              }
            }
            .input {
              border-radius: 4px;
            }
            .button {
              background: transparent url('img/search-white.png') no-repeat 50% 50%;
              border: 0;
              margin-left: 1rem;
            }
          }
        }
      }
    }
    .navbar-menu {
      justify-content: space-between;
      margin-top: 1rem;

      background: linear-gradient(270deg, rgba(26, 135, 194, 1) 0%, rgba(26, 135, 194, 1) 0%, rgba(15, 102, 159, 1) 100%, rgba(15, 102, 159, 1) 100%);
      color: $white;
      @include until ($desktop) {
        &.is-active {
          display: flex;
          flex-direction: column;
        }
      }
      > .navbar-item {
        flex-grow: 1;
        @include from ($desktop) {
          &:not(.search) {
            padding: 0;
          }
        }
        &.contacts {
          color: $white;
          margin-top: 1rem;
          border-top: 1px solid $white;
          .title {
            color: $white;
            text-align: center;
            font-weight: normal;
            margin-bottom: .5rem;
          }
          .item {
            margin-bottom: 0.5rem;
            a {
              font-weight: bold;
              display: block;
              color: $white;
            }
          }
          button {
            height: auto;
          }
        }
        .hamburger {
          color: $white;
          @include hamburger(2rem);
          display: inline;
          margin-right: 1.2rem;
        }
        > a, > span {
          font-weight: bold;
          text-align: center;
          font-size: 1.1rem;
          color: $white;
          display: block;
          width: 100%;
          line-height: 2.9;
          &:hover {
            background: $light-blue;
          }
          @include until ($desktop) {
            text-align: left;
          }
        }
        > .menu-list {
          display: none;
        }
        .navbar-dropdown {
          border: 0;
          background: $blue;
          overflow: visible;
          @include until ($desktop) {
            display: none;
            &.is-active {
              display: block;
            }
          }
          > .menu-list {
            .navbar-item {
              padding: 0;
              > a {
                padding: 0.3rem 0.5rem;
                color: $white;
                line-height: 1.5;
                white-space: normal;
                flex: none;
                width: 250px;
                padding: .75rem;
                font-size: .8rem;
                letter-spacing: .1em;
                text-transform: uppercase;
                &:hover {
                  background: $light-blue;
                }
              }
            }
            > li {
              display: flex;
              > a {
                padding: 0.3rem 0.5rem;
                color: $white;
                line-height: 1.5;
                white-space: normal;
                flex: none;
                padding: .75rem;
                font-size: .8rem;
                letter-spacing: .1em;
                text-transform: uppercase;
                &:hover {
                  background: $light-blue;
                }
              }
              .navbar-dropdown {
                display: none;
              }
              @include from($desktop) {
                .navbar-dropdown {
                  position: absolute;
                  top: 0;
                  left: 100%;
                  padding: 0;
                  border-radius: 0;
                  width: calc(190px * 4); // fck that
                  max-height: initial;
                  ul {
                    border-left: 0;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    li {
                      width: 25%;
                      a {
                        width: 190px;
                        img {
                          width: 100%;
                          max-height: initial;
                        }
                      }
                    }
                  }
                }
                &:hover {
                  .navbar-dropdown {
                    display: block;
                  }
                }
              }
            }
          }
        }
        &.search {
          .field {
            margin-left: auto;
            .control:first-child {
              width: 100%;
            }
            .button {
              background: $white url('img/header_search.png') no-repeat 50% 50%;
            }
            input, button {
              height: 2em;
            }
          }
          @include until ($desktop) {
            display: none;
          }
        }
      }
      @include from($desktop) {
        .navbar-dropdown {
          max-height: calc(100vh - 175px);
          overflow-y: auto;
          overflow-x: visible;
        }
      }
      @include until($desktop) {
        width: 100%;
        margin-top: 0;
        z-index: 10000;
        position: absolute;
      }
    }
  }
}
.product-list {
  .sort {
    border-bottom: 1px solid $grey;
    a {
      display: inline-block;
      margin-left: 1.3rem;
      &:after {
        content: "";
        display: inline-block;
        margin-left: .5rem;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid $grey;
        border-right: 2px solid $grey;
        vertical-align: middle;
      }
      &.asc:after {
        transform: rotate(-135deg) translate(-1px, -2px);
      }
      &.desc:after {
        transform: rotate(45deg) translate(-2px, -2px);
      }
    }
  }
  .item {
    a {
      color: $dark;
      padding: .1px;
      display: block;
      text-align: center;
      &:hover {
        .name {
          background: $blue;
          color: $white;
        }
      }
      img {
        width: 100%;
      }
      .name {
        background: rgba(231, 238, 243, 1); //lighten($blue, 55%);
        font-size: .9rem;
        padding: 0.75rem;
        margin-top: -.4rem;
        span {
          display: block;
          height: 44px;
          overflow: hidden;
        }
      }
    }
  }
}
.catalog-list {
  a {
    display: block;
    padding-bottom: .1px;
    text-align: center;
    &:hover {
      .name {
        background: $light-blue;
      }
    }
    img {
      width: 100%;
    }
    .name {
      background: $blue;
      font-size: .9rem;
      color: $white;
      padding: 0.75rem;
      margin-top: -.4rem;
      span {
        display: block;
        height: 44px;
        overflow: hidden;
      }
    }
  }
}
#feedback-form {
  margin: 2rem 0;
  .container {
    background: $white-ter;
    border: 1px solid $grey;
    padding: 2rem;
  }
}
.footer {
  padding: 0;
  font-size: 0.9rem;
  background: rgb(17, 81, 119);
  color: $white;
  .columns:first-child {
    margin-bottom: 0;
  }
  .title {
    color: $white;
    margin: .5rem 0;
  }
  .phone {
    font-size: 1rem;
  }
  .phone, .address, .email {
    margin-bottom: .5rem;
    font-weight: bold;
  }

  .logo {
    margin-left: 1rem;
    background: url('img/logo-white.png') no-repeat;
    background-size: contain;
    width: 290px;
    height: 75px;
    margin-right: -.7rem;
  }
  .copyright {
    margin: 5rem 1rem .5rem;
    &.small {
      text-align: center;
      margin-top: 1rem;
    }
  }
  a {
    color: $white;
  }
  .menu-list {
    a {
      color: $white;
      padding: .5rem;
      &:hover {
        background: transparent;
        color: $grey-lighter;
      }
    }
  }
  .contact {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    .item {
      padding: 0.75rem;
      &:last-child {
        margin-right: 0.75rem;
      }
    }
    @include until($tablet) {
      flex-direction: column;
      padding: 0 0.75rem;
    }
  }
  @include until ($desktop) {
    .columns {
    flex-wrap: wrap;
    }
    .contact {
      width: 100%;
      justify-content: space-around;
    }
  }
}

#product {
  .table {
    th {
      background: $primary;
      color: $white;
    }
  }
  .info {
    background: $white-ter;
    padding: .75rem;
    margin-bottom: 1rem;
    .availability {
      display: flex;
      justify-content: space-between;
      .status {
        font-weight: bolder;
        &:before {
          content: "";
          display: inline-block;
          width: 10px;
          vertical-align: middle;
          margin-right: .2rem;
        }
        &.present {
          color: $green;
          &:before {
            height: 4px;
            border-left: 2px solid $green;
            border-bottom: 2px solid $green;
            transform: rotate(-45deg);
          }
        }
        &.absent {
          position: relative;
          color: $red;
          &:before {
            border-bottom: 2px solid $red;
            margin-right: .4rem;
            transform: rotate(-45deg);
          }
          &:after {
            transform: rotate(45deg);
            position: absolute;
            left: 0;
            top: 13px;
            content: "";
            display: inline-block;
            width: 10px;
            vertical-align: middle;
            border-bottom: 2px solid $red;

          }
        }
      }
    }
    .price {
      margin-top: 1.5rem;
    }
    .button {
      font-weight: bold;
      font-size: 1.1rem;
    }
  }
  .delivery {
    .tariffs {
      margin: 0.5rem 0;
      .columns {
        margin: 0;
        .column {
          padding: 0.2rem 0;
        }
      }
      font-size: 0.9rem;
      .value {
        color: $green;
      }
    }
  }
  .icons {
    justify-content: space-between;
    .ico {
      text-align:center;
      a {
        color: $dark;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .img-thumbs {
    .slick-slide {
      margin: 0 0.5rem;
    }
  }
  .tabs {
    @include until($tablet) {
      ul {
        flex-direction: column;
        li {
          width: 100%;
        }
      }
    }
  }
  .tabs-content {
    > *:not(.is-active) {
      display: none;
    }
  }
}

#mse2_filters {
  .title {
    margin: .8rem 0;
  }
}

#mse2_sort {
  border-bottom: 1px solid $grey;
  padding: 0;
  margin: 0.75rem;
  a  {
    margin: 0 .5rem;
  }
}
.box.filter {
  border-radius: 0;
  border: 1px solid $blue;
  .filter-header  {
    margin: -1.25rem -1.25rem .5rem;
    padding: 1rem 0.75rem;
    color: $white;
    background: $blue;
    text-align: center;
  }
}
.breadcrumb a {
  white-space: initial;
}

@include until ($desktop) {
  .columns {
    margin: -0.75rem 0;
    .columns {
      margin: -0.75rem -0.75rem 0;
    }
  }
}
